import { FormGroup } from '@mui/material';
import React from 'react';
import {
    CONTROLLER_KEYS,
    CONTROLLER_LABELS,
    MAP_LAYER_NAMES,
    ROLE_GROUPS,
} from '../../../constants';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const DamageChronologyController = ({ selectedItems, setSelectedItems }) => {
    const { handleItemCheck } = useMenu();
    return (
        <FormGroup>
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.DAMAGE_CHRONOLOGY_SUM}
                checkbox={{
                    checked: selectedItems.includes(CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedItems(
                            isChecked
                                ? [...selectedItems, CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM]
                                : selectedItems.filter(
                                      (e) => e !== CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM
                                  )
                        );
                        handleItemCheck(
                            CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM,
                            CONTROLLER_KEYS.DAMAGE_CHRONOLOGY
                        );
                    },
                }}
                mapLayer={MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY}
                controllerItem={CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM}
                controllerKey={CONTROLLER_KEYS.DAMAGE_CHRONOLOGY}
                permissions={[...ROLE_GROUPS.FROST_PRODUCTS_LIMITED]}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.FROST_DAMAGE_CHRONOLOGY}
                checkbox={{
                    checked: selectedItems.includes(CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedItems(
                            isChecked
                                ? [...selectedItems, CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY]
                                : selectedItems.filter(
                                      (e) => e !== CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY
                                  )
                        );
                        handleItemCheck(
                            CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY,
                            CONTROLLER_KEYS.DAMAGE_CHRONOLOGY
                        );
                    },
                }}
            />
        </FormGroup>
    );
};

export default DamageChronologyController;
