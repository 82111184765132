import { MAP_LAYER_NAMES } from './mapLayerNames';
import { YIELD_TABLE_OPTIONS } from './cropAndYieldOptions';

const ROLES = {
    ADMIN: 'admin',
    AGRO: 'agro',
    API: 'api',
    EXPERT_DAMAGE: 'expert_damage',
    EXPERT_RISK_TAKING: 'expert_risk_taking',
    EXPERT_UW: 'expert_uw',
    FARMER: 'farmer',
    KARNAVIGATOR: 'karnavigator',
    MANAGER: 'manager',
    OPERATOR: 'operator',
    PUCCINI: 'puccini',
};

const ROLE_GROUPS = {
    FROST_PRODUCTS: [
        ROLES.ADMIN,
        ROLES.API,
        ROLES.EXPERT_RISK_TAKING,
        ROLES.KARNAVIGATOR,
        ROLES.MANAGER,
        ROLES.PUCCINI,
    ],
    FROST_PRODUCTS_LIMITED: [
        ROLES.ADMIN,
        ROLES.API,
        ROLES.KARNAVIGATOR,
        ROLES.MANAGER,
        ROLES.PUCCINI,
    ],
    ADMIN_PRODUCTS: [ROLES.ADMIN, ROLES.API, ROLES.KARNAVIGATOR, ROLES.MANAGER, ROLES.PUCCINI],
};

const STANDARD_FIELD_ZOOM_LEVEL = 9.7;
const STANDARD_MANAGER_FIELD_ZOOM_LEVEL = 8.5;
const STANDARD_REGION_ZOOM_LEVEL = 7;
const STANDARD_COUNTY_ZOOM_LEVEL = 5;
const STANDARD_COUNTRY_ZOOM_LEVEL = 1;

const MAP_ACCESS = {
    [MAP_LAYER_NAMES.YIELD_MAP]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE:
            ![ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) ||
            zoom >= STANDARD_FIELD_ZOOM_LEVEL,
        MAP_VARIANT_FOR_ZOOM_LEVEL:
            [ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) ||
            zoom >= STANDARD_REGION_ZOOM_LEVEL
                ? YIELD_TABLE_OPTIONS.MEPAR
                : YIELD_TABLE_OPTIONS.NUTS4,
    }),
    [MAP_LAYER_NAMES.CROP]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE:
            (![ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) &&
                zoom >= STANDARD_MANAGER_FIELD_ZOOM_LEVEL) ||
            zoom >= STANDARD_FIELD_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.FROST]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE:
            ![ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) ||
            zoom >= STANDARD_FIELD_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.DEEP_MAP]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE:
            ![ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) ||
            zoom >= STANDARD_FIELD_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.FROST_RISK]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE:
            (![ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) &&
                zoom >= STANDARD_MANAGER_FIELD_ZOOM_LEVEL) ||
            zoom >= STANDARD_FIELD_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY]: (role) => ({
        ZOOM_LEVEL_VISIBLE: [...ROLE_GROUPS.FROST_PRODUCTS_LIMITED].includes(role),
    }),
    [MAP_LAYER_NAMES.FROST_DAMAGE_CHRONOLOGY]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE:
            ![ROLES.EXPERT_RISK_TAKING, ROLES.EXPERT_DAMAGE, ROLES.EXPERT_UW].includes(role) ||
            zoom >= STANDARD_FIELD_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.RADAR]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE: zoom >= STANDARD_COUNTRY_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.RADAR_MEPAR]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE: zoom >= STANDARD_REGION_ZOOM_LEVEL,
    }),
    [MAP_LAYER_NAMES.CITY]: (role, zoom) => ({
        ZOOM_LEVEL_VISIBLE: zoom >= STANDARD_COUNTY_ZOOM_LEVEL,
    }),
};

function hasPermission(user, permissions = []) {
    return !(permissions.length > 0 && !permissions.some((permission) => permission === user.role));
}

function getZoomLevelVisibility(user, layer, zoom) {
    const mapAccessDefinition = MAP_ACCESS[layer];
    if (!mapAccessDefinition || !(mapAccessDefinition instanceof Function)) {
        return undefined;
    }

    return MAP_ACCESS[layer](user.role, zoom).ZOOM_LEVEL_VISIBLE;
}

function getMapVariantForZoomLevel(user, layer, zoom) {
    return MAP_ACCESS[layer](user.role, zoom).MAP_VARIANT_FOR_ZOOM_LEVEL;
}

// eslint-disable-next-line import/prefer-default-export
export { ROLES, ROLE_GROUPS, getZoomLevelVisibility, getMapVariantForZoomLevel, hasPermission };
